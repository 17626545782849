import React, { useContext } from 'react';
import { GlobalDataContext } from "../../context/context";
import { BsScissors } from 'react-icons/bs';
import { GiComb } from 'react-icons/gi';
// import { MdRoofing } from 'react-icons/md'


function Values() {
  const { rpdata } = useContext(GlobalDataContext);

  const valuesData = [
    {
      title: "Mision",
      description: rpdata?.dbValues?.[0].description,
      icon: <BsScissors fontSize={70} className='titleColorFt' />,
    },
    {
      title: "Vision",
      description: rpdata?.dbValues?.[1].description,
      icon: <GiComb fontSize={70} className='titleColorFt' />,
    },

  ]


  return (
    <>
      <div className="container mx-auto md:w-full py-10 flex md:flex-row flex-col justify-center items-center">
        {
          valuesData?.slice(0, 1).map((items, index) => {
            return (
              <div key={index} className="bg-white w-1/2 h-auto md:text-start text-center m-4 p-5 shadow-pink-300 shadow-lg cursor-pointer rounded transform hover:scale-105 duration-300 ease-in-out">
                <div className="flex justify-center">
                  {items.icon}
                </div>
                <div className="p-4 ">
                  <h2 className="text-2xl text-center uppercase">{items.title}</h2>
                  <p className='text-center pb-3'>
                    {items.description}
                  </p>
                </div>
              </div>
            )
          })
        }
        <div className='h-full'>
          <img
            src={rpdata?.dbPrincipal?.logo}
            alt='logo'
            loading='lazy'
            className='md:w-[85%] w-[90%]'
          />
        </div>
        {
          valuesData?.slice(1, 2).map((items, index) => {
            return (
              <div key={index} className="bg-white w-1/2 h-auto md:text-start text-center m-4 p-5 shadow-pink-300 shadow-lg cursor-pointer rounded transform hover:scale-105 duration-300 ease-in-out">
                <div className="flex justify-center">
                  {items.icon}
                </div>
                <div className="p-4 ">
                  <h2 className="text-2xl text-center uppercase">{items.title}</h2>
                  <p className='text-center pb-3'>
                    {items.description}
                  </p>
                </div>
              </div>
            )
          })
        }
      </div>
    </>
  );
}

export default Values;
