import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
// import { GiRibbonMedal, GiHammerNails } from "react-icons/gi";
// import { AiOutlineTeam } from "react-icons/ai";
// import { FaHammer } from "react-icons/fa";
// import { TbPlant2 } from "react-icons/tb";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {
  const { rpdata } = useContext(GlobalDataContext);
  // const BgImages = [
  //   bgImg,
  //   bgImg1,
  //   bgImg2,
  //   bgImg3,
  // ]
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.stock?.[5]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.stock?.[4]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.stock?.[1]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.stock?.[2]}")` }}
        ></figure>
        <div className="z-10 relative w-4/5 mx-auto h-[950px] md:h-[100vh]">
          <div className="w-full h-full pb-[15%] md:pb-[15%] flex flex-col justify-end items-center">
            <h1 className="text-white text-center">
              {rpdata?.dbSlogan?.[4].slogan}
            </h1>
            <p className="text-white text-center">{rpdata?.dbValues?.[1].description}</p>
            <div className="flex justify-center md:block">
              <ButtonContent />
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </>
  );
  // return (
  //   <>
  //     <div className=" w-full md:h-screen h-auto" >
  //       {
  //         BgImages.map((item, index) => {
  //           return (
  //             <figure
  //               key={index}
  //               className={`pic-${index + 1}`}
  //               style={{ background: `url("${item}")` }}
  //             ></figure>
  //           )
  //         })
  //       }

  //       <div className="z-10 relative w-4/5 mx-auto h-full flex  items-center md:pb-[80px] md:pt-[80px] pt-[80px] pb-[80px] justify-end">
  //         <div></div>
  //         <div className="md:w-1/2 w-[100%] bgshapes h-[500px] flex flex-col justify-center items-center relative">
  //           <div className="w-full flex flex-col justify-center items-center relative ">
  //             <h1 className="text-white text-center text-[35px] mb-2 md:text-[38px]">
  //               {title}
  //             </h1>
  //             <p className="text-white text-center">
  //               {texts}
  //             </p>

  //             <img 
  //             src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Background%2Fgrid_white-1.png?alt=media&token=03e5cc94-d670-44b8-9b42-6392c3b94ba2"
  //             alt="shapesgrid-nofound"
  //             className="absolute h-[200px] -bottom-20 right-5"/>
  //           </div>
  //           <div className="absolute -bottom-20 py-5">
  //             <ButtonContent />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     {/* <div className=" md:w-[70%] w-[100%] bg-2 text-white md:-mt-32 -mb-12 -mt-20 relative md:py-14 py-2 homi">
  //       <ul className="flex md:flex-row flex-col justify-around ml-5 md:ml-0">
  //         <h5 className="flex items-center" data-aos="zoom-in">
  //           <GiHammerNails
  //             fontSize={70}
  //             className="text-white mr-2"
  //           />
  //           BEST SERVICE</h5>
  //         <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1000">
  //           <AiOutlineTeam
  //             fontSize={70}
  //             className="text-white"
  //           />
  //           PROFESSIONAL TEAM</h5>
  //         <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1500">
  //           <GiRibbonMedal
  //             fontSize={70}
  //             className="text-white"
  //           />BEST QUALITY</h5>
  //       </ul>
  //     </div> */}
  //   </>
  // );
}

export default HeroSection;
